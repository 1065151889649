<template>
  <div class="responsive-table">
    <!-- <pre>{{form}}</pre> -->
    <div class="text-center" v-if="messages.length == 0 && !loading">
      <i class="far fa-envelope font-size-80"></i>
      <h5 v-html="$t('warnings.email.none-email')"></h5>
      <router-link
        to="/mail/send"
        class="btn btn-success"
        aria-expanded="false"
      >
        {{$t('warnings.email.send-email')}}
      </router-link>
    </div>
    <table class="table table-borderless" v-if="!loading && messages.length > 0">
      <thead>
        <tr>
          <th scope="col">{{$t('generic-str.date')}}</th>
          <th scope="col">{{$tc('generic-str.message', 1)}}</th>
          <th scope="col">Status</th>
          <th scope="col">Aberturas</th>
          <th scope="col">Clicks</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="message in messages" :key="message.id">
          <td>
            <router-link :to="`/mail/history/${message.id}`">
              {{ message.created_at | formatDate24 }}
            </router-link>
          </td>
          <td class="text-truncate">
            <span class="font-weight-500">Para: {{ message.to }}</span>
            <br />
            {{ message.subject }}
          </td>
          <td data-label="Status">
            <span
              v-if="message.status == 'accepted'"
              class="badge badge-light"
              >{{$tc('generic-str.status.lbl-pending', 1)}}</span
            >
            <span
              v-else-if="message.status == 'queued'"
              class="badge badge-secondary"
              >{{$t('generic-str.status.lbl-queue')}}</span
            >
            <span
              v-else-if="message.status == 'sending'"
              class="badge badge-accent"
              >{{$t('generic-str.status.lbl-sending')}}</span
            >
            <span
              v-else-if="message.status == 'sent'"
              class="badge badge-info"
              >{{$t('generic-str.status.lbl-sent')}}</span
            >
            <span
              v-else-if="message.status == 'failed'"
              class="badge badge-danger"
              v-tooltip.top="
                `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
              "
              >{{$t('generic-str.status.lbl-failure')}}</span
            >
            <span
              v-else-if="message.status == 'delivered'"
              class="badge badge-success"
              >{{$t('generic-str.status.lbl-delivered')}}</span
            >
            <span
              v-else-if="message.status == 'open'"
              class="badge badge-info"
              >Aberto</span
            >
            <span
              v-else-if="message.status == 'click'"
              class="badge badge-dark"
              >Click</span
            >
            <span
              v-else-if="message.status == 'bounce'"
              class="badge badge-danger"
              v-tooltip.top="
                `O servidor de recebimento não pôde ou não aceitaria e-mails para este destinatário
                permanentemente. Se um destinatário cancelou a assinatura de seus e-mails
                anteriormente, a mensagem será descartada.`
              "
              >Devolvido</span
            >
            <span
              v-else-if="message.status == 'bounced'"
              class="badge badge-danger"
              v-tooltip.top="
                `O servidor de recebimento não pôde ou não aceitaria e-mails para este destinatário
                permanentemente. Se um destinatário cancelou a assinatura de seus e-mails
                anteriormente, a mensagem será descartada.`
              "
              >Devolvido</span
            >
            <span
              v-else-if="message.status == 'opened'"
              class="badge badge-success"
              >{{$t('generic-str.status.lbl-open')}}</span
            >
            <span
              v-else-if="message.status == 'dropped'"
              class="badge badge-danger"
              v-tooltip.top="
                `Pode ter sido descartada pelos seguintes motivos: conteúdo de spam,
                endereço não inscrito, endereço devolvido, inválido`
              "
              >{{$tc('generic-str.status.lbl-dropped', 1)}}</span
            >
            <span
              v-else-if="message.status == 'deferred'"
              class="badge badge-dark"
              v-tooltip.top="
                `O servidor receptor rejeitou temporariamente a mensagem. Será realizada
                uma nova tentativa de envio`
              "
              >{{$t('generic-str.status.lbl-postponed')}}</span
            >
            <span
              v-else-if="message.status == 'blocked'"
              class="badge badge-danger"
              v-tooltip.top="
                `O servidor receptor não pôde ou não aceitou a mensagem temporariamente.
                Se um destinatário cancelou a assinatura de seus e-mails anteriormente,
                a mensagem será descartada.`
              "
              >{{$t('generic-str.status.lbl-blocked')}}</span
            >
          </td>
          <td>{{ message.opens }}</td>
          <td>{{ message.clicks }}</td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="loading"
      class="static qt-block-ui"
      style="padding: 120px"></div>
    <!-- <div
      v-if="loading"
      class="relative qt-block-ui"
      style="padding: 120px"
    /> -->
    <br />
    <pagination
      :lastPage="pages"
      :currentPage="form.page"
      @change="fetch"
    />
  </div>
</template>

<script>
// @ is an alias to /src
// import PageHeader from '@/components/PageHeader.vue';
import Vue from 'vue';
import Pagination from '@/components/Pagination.vue';
import moment from 'moment';
import MailService from '@/services/mail.service';
import CustomerService from '@/services/customer.service';

export default {
  name: 'MailReportsHistory',
  components: {
    // PageHeader,
    Pagination,
  },
  props: ['dateGte', 'dateLte'],
  watch: {
    dateGte(newVal, oldVal) {
      this.beginDate = newVal;
      Vue.set(this.form.scheduled_at, 'gte', this.beginDate);
      console.log('Prop changed [dateGte]: ', newVal, ' | was: ', oldVal, this.form.scheduled_at);
    },
    dateLte(newVal, oldVal) {
      this.endDate = newVal;
      Vue.set(this.form.scheduled_at, 'lte', this.endDate);
      console.log('Prop changed [dateLte]: ', newVal, ' | was: ', oldVal, this.form.scheduled_at);
    },
  },
  data() {
    return {
      messages: [],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: true,
      beginDate: '2023-03-01 00:00:00',
      endDate: '2023-03-31 23:59:00',
      form: {
        page: 1,
        id: '',
        status: '',
        from: '',
        to: '',
        scheduled_at: {
          // gte: '2023-03-01 00:00:00',
          // lte: '2023-03-31 23:59:00',
        },
      },
      pages: 1,
      loading: false,
      exporting: false,
    };
  },
  created() {
    if (!this.$store.state.account.whitelabel_id) {
      this.fetchCustomers();
    }

    console.log('Prop changed [created]: ', [this.dateGte, this.dateLte]);

    this.beginDate = this.dateGte;
    this.form.scheduled_at.gte = this.dateGte;

    this.endDate = this.dateLte;
    this.form.scheduled_at.lte = this.dateLte;

    Vue.set(this.form.scheduled_at, 'gte', this.dateGte);
    Vue.set(this.form.scheduled_at, 'lte', this.dateLte);

    this.fetch(1);
  },
  methods: {
    fetch(page) {
      console.log('mail/fetch', { page });

      this.form.page = page;
      this.loading = true;
      MailService.getMessages(this.form).then(
        (response) => {
          console.log('mail/fetch', { response });
          this.loading = false;
          this.messages = response.data || (
            Array.isArray(response) ? response : []
          );
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.scheduled_at.gte = this.beginDate;
      this.form.scheduled_at.lte = this.endDate;
    },
    reportExport(blob, name) {
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, name);
      } else {
        // feature detection
        // Browsers that support HTML5 download attribute
        console.log(blob);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          document.body.removeChild(link);
        }, 1000);
      }
    },
    exportData() {
      this.exporting = true;
      MailService.exportMails({
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/mail/reports/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;

  &::v-deep {
    table {
      text-align: center;

      th, td {
        padding: .625em;
        text-align: center;
      }

      th {
        font-size: .85em;
        letter-spacing: .1em;
        text-transform: uppercase;
        font-weight: 500;
        vertical-align: bottom;
        color: #212529 !important;
      }

      td {
        font-weight: 300 !important;
      }

      thead, tbody {
        color: var(--foreground);
      }
    }
  }
}
</style>
